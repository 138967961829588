import { IConfig } from 'lib/src/types';
import { EnvType, VisionType, VisionModeType } from 'lib/src/types';

export const configBase: IConfig = {
  isTestEnv: true,
  lang: 'zh-cn',
  envType: EnvType.none,
  pusherKey: '976e310fdab2b1b6ef70',
  url: {
    home: '/home',
    login: '/login'
  },
  localStorage: {
    groupName: 'kr',
    storeName: 'kv'
  },
  cache: {
    enabled: true
  },
  logServer: {
    url: 'https://log-push.dachiyidun.xyz'
  },
  timeout: {
    loadConfig: 20000,
    speedTest: 25000,
    speedoMeterLock: 3000,
    jsBridgeReady: 1000
  },
  vision: VisionType.app,
  visionMode: VisionModeType.none
};
