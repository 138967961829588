import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'lib';
import { ShippingOrder } from 'lib/src/types/schema';
import { LanguageService } from '../../services/language.service';
import { ShippingOrderInfoModalComponent } from '../shipping-order-info-modal/shipping-order-info-modal.component';

@Component({
  selector: 'kr-shipping-order-card',
  templateUrl: './shipping-order-card.component.html',
  styleUrls: ['./shipping-order-card.component.scss']
})
export class ShippingOrderCardComponent implements OnInit {
  @Input() order: ShippingOrder;

  constructor(
    private modalSer: ModalService,
    public lang: LanguageService
  ) { }

  ngOnInit(): void {
  }

  openShippingModal(orderId: string) {
    this.modalSer.open(ShippingOrderInfoModalComponent, {
      hasBackdrop: true,
      // width: '100%',
      // height: '100%',
      closeOnBackdropClick: true,
    }, component => {
      component.order = this.order;
    });
  }
}
