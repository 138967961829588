import { Clipboard } from '@angular/cdk/clipboard';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { MessageService, ModalService } from 'lib';
import { MarketInvestorInfo, PaginatorInfo, Query } from 'lib/src/types/schema';
import { InvestorExchangeWebsiteInfo_MarketInvestorsArgs } from 'lib/types/schema';
import { OnsDialogElement } from 'onsenui';
import { timer } from 'rxjs';
import { QueryOptionsComponent } from '../query-options/query-options.component';
import { EXCHANGE_WEBSITE_INFO } from '../../helpers/api';
import { LanguageService } from '../../services/language.service';
import { PaymentService } from '../../services/payment.service';
import { WalletService } from '../../services/wallet.service';
import { InvestorService } from '../../services/investor.service';

@Component({
  selector: 'kr-market-online-investor',
  templateUrl: './market-online-investor.component.html',
  styleUrls: ['./market-online-investor.component.scss']
})
export class MarketOnlineInvestorComponent implements OnInit {
  @ViewChild('queryOptionDialog') queryOptionDialog: ElementRef<OnsDialogElement>;
  @ViewChild('queryOption') queryOption: QueryOptionsComponent;

  investors: MarketInvestorInfo[];
  inputAccount: string | null;
  paginatorInfo: PaginatorInfo;
  currentPage: number = 1;
  pageSize: number;
  total: number = 1;
  pageSizeOptions: number[] = [10, 20];
  loading = false;
  percent: number = 0;

  constructor(
    private modalSer: ModalService,
    private apollo: Apollo,
    private clipbaord: Clipboard,
    private messageSer: MessageService,
    public walletSer: WalletService,
    public lang: LanguageService,
    public paymentSer: PaymentService,
    public investorSer: InvestorService,
  ) { }

  ngOnInit(): void {
    this.pageSize = this.pageSizeOptions[0];
    this.investorSer.account.subscribe(account => {
      this.inputAccount = account;
      this.query({ page: this.currentPage, first: this.pageSize, input: { account: account } });
    })
  }

  openQueryPopup() {
    this.queryOptionDialog.nativeElement.show();
    // this.modalSer.open(QueryOptionsComponent, {
    //   hasBackdrop: true,
    //   closeOnBackdropClick: true
    // },(c) => {
    //   c.confirm = (cc: any) => {
    //     this.queryOrder({
    //       input: {
    //         startTime: c.startTime,
    //         endTime: c.endTime
    //       }
    //     });
    //     c.close();
    //   }
    // })
  }

  async query(args: InvestorExchangeWebsiteInfo_MarketInvestorsArgs = {}) {
    if (this.loading) {
      return;
    }

    args.input = {
      ...args.input
    };

    this.loading = true;
    this.percent = 0;
    this.setPercent();
    this.apollo.query<Query>({
      query: EXCHANGE_WEBSITE_INFO,
      variables: args,
      fetchPolicy: 'no-cache'
    }).subscribe({
      next: resp => {
        this.investors = resp.data.me?.exchangeWebsiteInfo?.marketInvestors!.data ?? [];
        this.paginatorInfo = resp.data.me?.exchangeWebsiteInfo?.marketInvestors?.paginatorInfo!;
        this.currentPage = this.paginatorInfo?.currentPage!;
        this.total = this.paginatorInfo?.total!;
      },
      complete: () => this.loading = false
    });
  }

  setPercent(): void {
    timer(0, 50).subscribe((n) => {
      this.percent = this.percent + 10;
      if (this.percent > 100) this.percent = 100;
    });
  }

  sizeChagne(first: number) {
    this.pageSize = first;
    this.query({ page: 1, first: this.pageSize })
  }

  pageChange(page: number) {
    this.currentPage = page;
    this.query({ page: this.currentPage, first: this.pageSize });
  }
}
