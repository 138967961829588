import { Clipboard } from '@angular/cdk/clipboard';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Apollo } from 'apollo-angular';
import dayjs from 'dayjs';
import { F2eHelper, MessageService, ModalService } from 'lib';
import { InvestorHistory_PurchaseOrderHistoryArgs, InvestorQuotaRecord, PaginatorInfo, Query } from 'lib/src/types/schema';
import { OnsDialogElement } from 'onsenui';
import { QueryOptionsComponent } from '../query-options/query-options.component';
import { QUOTA_HISTORY_QUERY } from '../../helpers/api';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'kr-quota-history',
  templateUrl: './quota-history.component.html',
  styleUrls: ['./quota-history.component.scss'],
})
export class QuotaHistoryComponent implements OnInit, AfterViewInit {
  @ViewChild('queryOptionDialog') queryOptionDialog: ElementRef<OnsDialogElement>;
  @ViewChild('queryOption') queryOption: QueryOptionsComponent;

  history: InvestorQuotaRecord[];
  paginatorInfo: PaginatorInfo;
  currentPage: number = 1;
  pageSize: number;
  total: number = 1;
  pageSizeOptions: number[] = [10, 20];
  loading = false;
  date = [dayjs().startOf('month').toISOString(), dayjs().startOf('day').toISOString()];

  constructor(
    private modalSer: ModalService,
    private apollo: Apollo,
    private clipbaord: Clipboard,
    private messageSer: MessageService,
    public lang: LanguageService
  ) { }

  ngOnInit(): void {
    this.pageSize = this.pageSizeOptions[0];
    this.query({ page: this.currentPage, first: this.pageSize });
  }

  ngAfterViewInit() {
    this.initQueryOption();
  }

  openQueryPopup() {
    this.queryOptionDialog.nativeElement.show();
    // this.modalSer.open(QueryOptionsComponent, {
    //   hasBackdrop: true,
    //   closeOnBackdropClick: true
    // },(c) => {
    //   c.confirm = (cc: any) => {
    //     this.query({
    //       input: {
    //         startTime: c.startTime,
    //         endTime: c.endTime
    //       }
    //     });
    //     c.close();
    //   } 

    // })
  }

  async query(args: InvestorHistory_PurchaseOrderHistoryArgs = {}) {
    if (this.loading) {
      return;
    }
    // queryOption 尚未初始化完成，等一下
    if (!this.queryOption?.startTime && !this.queryOption?.endTime) {
      await F2eHelper.sleep(100);
    }
    args.input = {
      /**  订单建立开始时间  */
      startTime: dayjs(this.date[0]).format('YYYY-MM-DD 00:00:00'),
      /**  订单建立结束时间  */
      endTime: dayjs(this.date[1]).format('YYYY-MM-DD 23:59:59'),
      ...args.input
    };
    this.loading = true;
    this.apollo.query<Query>({
      query: QUOTA_HISTORY_QUERY,
      variables: args,
      fetchPolicy: 'no-cache'
    }).subscribe({
      next: resp => {
        this.history = resp.data.me?.histories?.quotaHistory!.data ?? [];
        this.paginatorInfo = resp.data.me?.histories?.quotaHistory?.paginatorInfo!;
        this.currentPage = this.paginatorInfo?.currentPage!;
        this.total = this.paginatorInfo?.total!;
      },
      complete: () => this.loading = false
    });
  }

  sizeChagne(first: number) {
    this.pageSize = first;
    this.query({ page: 1, first: this.pageSize })
  }

  pageChange(page: number) {
    this.currentPage = page;
    this.query({ page: this.currentPage, first: this.pageSize });
  }

  copy(text: string) {
    this.clipbaord.copy(text);
    this.messageSer.notify('已複製', 600);
  }

  private initQueryOption() {
    // this.queryOption.confirm = () => {
    //   if (isBefore(this.queryOption.startTime, this.queryOption.endTime)) {
    //     return this.messageSer.notify({ type: 'error', text: '起始时间无法晚于结束时间' });
    //   }

    //   this.query();

    //   this.queryOptionDialog.nativeElement.hide();
    // };
    this.query();
    // this.queryOption.cancel = () => {
    //   this.queryOptionDialog.nativeElement.hide();
    // };
    // this.queryOption.close = () => {
    //   this.queryOptionDialog.nativeElement.hide();
    // };
  }
}
