import { Component, OnInit } from '@angular/core';
import { MessageService } from 'lib';
import { InvestorService } from '../../services/investor.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { Apollo } from 'apollo-angular';
import { Mutation } from 'lib/types/schema';
import { SET_NAME_MUTATION } from '../../helpers/api';
import { finalize } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'kr-account-setting',
  templateUrl: './account-setting.component.html',
  styleUrls: ['./account-setting.component.scss']
})
export class AccountSettingComponent implements OnInit {
  domain: string = location.host;
  isEdit = false;
  loading = false;
  form: FormGroup = this.formBuilder.group({
    name: ['', [Validators.required]],
  });

  constructor(
    public investorSer: InvestorService,
    private clipboard: Clipboard,
    private messageSer: MessageService,
    private apollo: Apollo,
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit(): void { }

  copyToClipboard(text: string) {
    this.clipboard.copy(text);
    this.messageSer.notify('已复制', 600);
  }

  setName() {
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    if (this.form.invalid || this.loading) return;
    this.loading = true;
    this.apollo.mutate<Mutation>({
      mutation: SET_NAME_MUTATION,
      variables: { input: this.form.value }
    }).pipe(finalize(() => this.loading = false))
      .subscribe(resp => {
        if (resp.data?.investor?.setName?.success) {
          this.messageSer.notify('设置成功');
          this.investorSer.investorInfoQuery.refetch();
          this.close();
        }
        this.loading = false;
      }, (err) => {
        console.log(err);
        this.loading = false;
      })
  }

  close() {
    this.isEdit = false;
    this.resetForm();
  }

  resetForm(): void {
    this.form.reset();
    for (const key in this.form.controls) {
      this.form.controls[key].markAsPristine();
      this.form.controls[key].updateValueAndValidity();
    }
  }
}
