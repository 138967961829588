import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import dayjs from 'dayjs';
import { MessageService } from 'lib';
import { ChildInvestor, InvestorTeamMutation_EditChildInvestorArgs, InvestorTeam_ChildInvestorArgs, InvestorTeam_TeamMemberProfitReportsArgs, InvestorTransactionStatistic, Mutation, PaginatorInfo, PersonalProfitReportInput, Query } from 'lib/src/types/schema';
import { filter, finalize, take } from 'rxjs/operators';
import { CHILD_INVESTOR_QUERY, EDIT_CHILD_INVESTOR_MUTATION, SELF_PROFIT_REPORT_QUERY, TEAM_MEMBER_PROFIT_REPORTS_QUERY, TEAM_PROFIT_REPORT_QUERY } from '../../helpers/api';
import { InvestorService } from '../../services/investor.service';
import { BonusGroupService } from '../../services/bonus-group.service';
import { WalletService } from '../../services/wallet.service';

@Component({
  selector: 'kr-team-profit',
  templateUrl: './team-profit.component.html',
  styleUrls: ['./team-profit.component.scss']
})
export class TeamProfitComponent implements OnInit {
  selfReport: InvestorTransactionStatistic;
  teamReport: InvestorTransactionStatistic[];
  members: InvestorTransactionStatistic[];
  paginatorInfo: PaginatorInfo;
  currentPage: number = 1;
  pageSize: number;
  total: number = 1;
  pageSizeOptions: number[] = [10, 20];
  selfLoading = false;
  teamLoading = false;
  membersLoading = false;
  searchAccount: string;
  accountBreadcrumb: string[] = [];
  date = [dayjs().startOf('month').toISOString(), dayjs().startOf('day').toISOString()];

  isInvestorSettingVisible = false;
  investor: ChildInvestor;
  selectedAccount = ''
  childInvestorSetting = {
    netEasePercent: 0,
    yohoPercent: 0,
    alipayPercent: 0,
    alipayScanPercent: 0,
    teamMemberQuota: 0
  }
  // 設置下限不得低於當前值
  netEaseMin = 0;
  alipayMin = 0;
  alipayScanMin = 0;
  investorLoading = true;
  submitting = false

  constructor(
    private apollo: Apollo,
    private messageSer: MessageService,
    public investorSer: InvestorService,
    public bonusGroupSer: BonusGroupService,
    public walletSer: WalletService,
  ) { }

  async ngOnInit() {
    this.pageSize = this.pageSizeOptions[0];
    await this.investorSer.ready.pipe(filter(ready => ready), take(1)).toPromise();
    this.searchAccount = this.investorSer.account.value!;
    this.accountBreadcrumb = [this.searchAccount];
    this.membersQuery(this.currentPage, this.pageSize);
  }

  async ngAfterViewInit() {
    await this.investorSer.ready.pipe(filter(ready => ready), take(1)).toPromise();
    this.query();
  }

  async query() {
    this.selfProfitQuery();
    this.teamProfitQuery();
    this.membersQuery(this.currentPage, this.pageSize);
  }

  private selfProfitQuery() {
    let input: PersonalProfitReportInput = {
      account: this.searchAccount,
      startTime: dayjs(this.date[0]).format('YYYY-MM-DD 00:00:00'),
      endTime: dayjs(this.date[1]).format('YYYY-MM-DD 23:59:59'),
    };
    this.selfLoading = true;
    this.apollo.query<Query>({
      query: SELF_PROFIT_REPORT_QUERY,
      variables: { input },
      fetchPolicy: 'no-cache'
    }).subscribe({
      next: resp => {
        this.selfReport = resp.data.me?.team?.personalProfitReport!;
      },
      complete: () => this.selfLoading = false
    });
  }

  private teamProfitQuery() {
    const account = this.searchAccount;
    let input: PersonalProfitReportInput = {
      account,
      startTime: dayjs(this.date[0]).format('YYYY-MM-DD 00:00:00'),
      endTime: dayjs(this.date[1]).format('YYYY-MM-DD 23:59:59'),
    };
    this.teamLoading = true;
    this.apollo.query<Query>({
      query: TEAM_PROFIT_REPORT_QUERY,
      variables: { input },
      fetchPolicy: 'no-cache'
    }).subscribe({
      next: resp => {
        this.teamReport = [resp.data.me?.team?.teamMemberProfitReport!];
      },
      complete: () => this.teamLoading = false
    });
  }

  async showEditChildInvestor(account: string) {
    this.selectedAccount = account;
    this.isInvestorSettingVisible = true;
    await this.getChildInvestor();
    this.initChildInvestorForm();
  }

  closeModal() {
    this.isInvestorSettingVisible = false;
    this.query();
  }

  private async membersQuery(page: number = 1, first: number) {
    const account = this.searchAccount;
    let args: InvestorTeam_TeamMemberProfitReportsArgs;
    if (this.membersLoading) {
      return;
    }
    args = {
      input: {
        account,
        startTime: dayjs(this.date[0]).format('YYYY-MM-DD 00:00:00'),
        endTime: dayjs(this.date[1]).format('YYYY-MM-DD 23:59:59'),
      },
      page,
      first
    };
    this.membersLoading = true;
    this.apollo.query<Query>({
      query: TEAM_MEMBER_PROFIT_REPORTS_QUERY,
      variables: args,
      fetchPolicy: 'no-cache'
    }).subscribe({
      next: resp => {
        this.members = resp.data.me?.team?.teamMemberProfitReports!.data ?? [];
        this.paginatorInfo = resp.data.me?.team?.teamMemberProfitReports!.paginatorInfo!;
        this.currentPage = this.paginatorInfo?.currentPage!;
        this.total = this.paginatorInfo?.total!;
      },
      complete: () => this.membersLoading = false
    });
  }

  sizeChagne(first: number) {
    this.pageSize = first;
    this.membersQuery(1, this.pageSize)
  }

  pageChange(page: number) {
    this.currentPage = page;
    this.membersQuery(this.currentPage, this.pageSize);
  }

  drillDownChild(account: string) {
    const idx = this.accountBreadcrumb.findIndex(a => a === account);
    if (idx === -1) {
      this.accountBreadcrumb.push(account);
    } else {
      this.accountBreadcrumb.splice(idx + 1);
    }
    this.searchAccount = account;
    this.teamProfitQuery();

    this.query();
  }

  getProfit() {
    return this.messageSer.notify({ type: 'error', text: '请联系客服' });
  }

  async initChildInvestorForm() {
    await this.bonusGroupSer.ready;
    this.netEaseMin = Math.max(this.bonusGroupSer.netEaseMin, this.investor.netEasePercent || 0);
    this.alipayMin = Math.max(this.bonusGroupSer.alipayMin, this.investor.alipayPercent || 0);
    this.alipayScanMin = Math.max(this.bonusGroupSer.alipayScanMin, this.investor.alipayScanPercent || 0);
    this.childInvestorSetting = {
      netEasePercent: this.investor.netEasePercent,
      yohoPercent: this.investor.yohoPercent,
      alipayPercent: this.investor.alipayPercent,
      alipayScanPercent: this.investor.alipayScanPercent,
      teamMemberQuota: this.investor.teamMemberQuota
    }
  }

  private async getChildInvestor() {
    const variables: InvestorTeam_ChildInvestorArgs = {
      input: {
        account: this.selectedAccount
      }
    };
    const resp = await this.apollo.query<Query>({
      query: CHILD_INVESTOR_QUERY,
      variables,
      fetchPolicy: 'no-cache'
    }).toPromise();
    this.investorLoading = false;
    if (resp) {
      this.investor = resp.data.me?.team?.childInvestor!;
    }
  }

  submitSetting() {
    if (this.submitting) return;
    this.submitting = true;
    const variables: InvestorTeamMutation_EditChildInvestorArgs = {
      input: {
        netEasePercent: this.childInvestorSetting.netEasePercent.toString(),
        yohoPercent: this.childInvestorSetting.yohoPercent.toString(),
        alipayPercent: this.childInvestorSetting.alipayPercent.toString(),
        alipayScanPercent: this.childInvestorSetting.alipayScanPercent.toString(),
        purchaseAlipayPercent: `${this.bonusGroupSer.purchaseAlipayMin}`,
        teamMemberQuota: this.childInvestorSetting.teamMemberQuota,
        account: this.selectedAccount
      }
    };
    this.apollo.mutate<Mutation>({
      mutation: EDIT_CHILD_INVESTOR_MUTATION,
      variables
    }).pipe(finalize(() => this.submitting = false))
      .subscribe(resp => {
        if (resp.data?.investor?.team?.editChildInvestor?.success) {
          this.messageSer.notify('編輯成功');
          this.closeModal();
        }
      })
  }
  get bonus() {
    if (this.walletSer.wallet?.value?.bonus == null) return 0
    return this.walletSer.wallet?.value?.bonus
  }
}
