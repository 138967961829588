import { Component } from '@angular/core';
import { WalletService } from '../../services/wallet.service';
import { Decimal } from 'decimal.js';

@Component({
  selector: 'kr-wallet-block',
  templateUrl: './wallet-block.component.html',
  styleUrls: ['./wallet-block.component.scss']
})
export class WalletBlockComponent {
  Decimal = Decimal;

  constructor(
    public walletSer: WalletService
  ) { }

  get availableQuota() {
    if (this.walletSer.wallet?.value?.quota == null) return 0
    return new Decimal(this.walletSer.wallet?.value?.quota!)
      .sub(this.walletSer.quota?.value?.retainedQuota!)
      .toNumber()
  }

  get totalQuota() {
    return new Decimal(this.walletSer.wallet.value?.quota ?? 0).toNumber()
  }

  get frozen() {
    const cnyFrozen = new Decimal(this.walletSer.wallet.value?.shippingAmount ?? 0)
      .add(this.walletSer.wallet.value?.purchasingAmount ?? 0)
      .add(this.walletSer.wallet.value?.frozenQuota ?? 0)
    return cnyFrozen.toNumber()
  }

  refresh() {
    this.walletSer.refetchWallets();
  }
}